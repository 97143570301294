import styles from './ProductTiles.module.scss';
import { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import OrderBox from 'components/objects/productList/b2c/OrderBox';
import { ProductPrice, ProductThumbnail } from 'components/primitives/product';
import { Placeholder } from 'components/primitives/placeholders';
import { routesBuilder } from 'routes';
import { Link } from 'components/primitives/links';
import { ProductTrackingContext, WithProductClickTracking } from 'components/objects/analytics';
import { AbilityTo } from 'behavior/user/constants';
import { useHasAbilities } from 'components/objects/user';

const ProductTile = ({ product, noImage, tilesPerView }) => {
  const ref = useRef(null);
  const { id, url, title, image } = product;
  const getRouteData = routesBuilder.forProduct.bind(null, id);

  useEffect(() => {
    if (!tilesPerView)
      return;

    ref.current.style.width = 100 / tilesPerView + '%';

    return () => ref.current.style.width = '';
  }, [tilesPerView]);

  const { trackingSource } = useContext(ProductTrackingContext);

  const canOrder = useHasAbilities(AbilityTo.OrderProducts)[0];

  const namePlaceholder = <Placeholder className={styles.namePlaceholder} />;

  return (
    <ProductTrackingContext.Provider value={{ product, trackingSource }}>
      <article className={styles.productTile} ref={ref} aria-label={`${title} (${id})`}>
        <div className={styles.tileTop}>
          <div className={styles.productImg}>
            <WithProductClickTracking>
              {handleClick => (
                <Link onClick={handleClick} draggable="false" url={url} to={getRouteData}>
                  <ProductThumbnail draggable="false" src={image && image.small} noImageSrc={noImage} title={title} />
                </Link>
              )}
            </WithProductClickTracking>
          </div>
          <div className={styles.productDescription}>
            <WithProductClickTracking>
              {handleClick => (
                <Link onClick={handleClick} url={url} to={getRouteData} draggable="false" className={styles.productTitle}>
                  {title}
                </Link>
              )}
            </WithProductClickTracking>
            <div className={styles.productId}>
              <span className={styles.name}>
                <SimpleText textKey="General_Product_Id" placeholder={namePlaceholder} />
              </span>
              <span className={styles.value}>{id}</span>
            </div>
          </div>
        </div>
        <div className={styles.tileBottom}>
          <div className={styles.productAction}>
            <ProductPrice salesPrice={product.price} basePrice={product.listPrice} />
          </div>
          {/*Ticket 156432: [HDM] 3.2 Product pages – Show price per m2*/}
          {product.isCalculatedProduct &&
            <div className={`${styles.productAction} display-flex`}>
            <ProductPrice salesPrice={product.pricePerMeterSquare} basePrice={product.pricePerMeterSquare} />
            {product.pricePerMeterSquare != null ? <div className="price-per-m2-block"><SimpleText textKey="Price_Per_M2" /><sup>2</sup></div> : null}
            </div>
          }
          {canOrder && <OrderBox product={product} className={styles.orderBox} withoutBackTo />}
        </div>
      </article>
    </ProductTrackingContext.Provider>
  );
};

ProductTile.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
      small: PropTypes.string,
    }),
  }).isRequired,
  noImage: PropTypes.string,
  tilesPerView: PropTypes.number,
};

export default ProductTile;
